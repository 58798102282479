import * as React from "react";
import { Layout, Menu, Breadcrumb } from "antd";
import { Tabs, Radio, Tag } from "antd";
import spansberry from "../images/spansberry.png";
import apogee1 from "../images/Apogee1.png";
import apogee2 from '../images/Apogee2.png';
import apogee3 from "../images/Apogee3.png";
import anonymousDiscussion from "../images/anonymousDiscussion.png";
import ck from "../images/CK-1.png";

const { TabPane } = Tabs;

import "./projects.scss";

const ProjectsPage = () => {
  return (
    <Layout id="projects" className="layout projects" style={{ padding: "50px 15%" }}>
      <div className="header" style={{marginBottom:'20px'}}>things that i've built.</div>
      <div className="project_header">
        <div className="project_change">
          <div className="project_description">
            <div>
              <div className="project_title">CoinKeep</div>
            </div>
            <div className="project_desc_card">
              <div>
                CoinKeep is a personal finance management app that helps you track your income,
                expenses, and budget. You can easily see where your money is
                going and adjust your spending habits. CoinKeep is a web-app, but using CoinKeep
                telegram bot, you can add transactions with your phone as well.
              </div>
            </div>

            <div
              className="tags"
              style={{
                justifyContent: "flex-end",
                paddingRight: 0,
                paddingLeft: "50px",
              }}
            >
              <Tag color="blue">ReactJS</Tag>
              <Tag color="blue">NodeJS</Tag>
              <Tag color="blue">Postgresql</Tag>
              <Tag color="blue">Prisma</Tag>
              <Tag color="blue">Telegram Bot</Tag>
            </div>
          </div>

          <a
            className="project_image"
            style={{
              boxShadow: "none",
              display: "flex",
              gap: "0 10px",
              gridColumn: "1/10",
            }}
          >
            <a target="_blank" href="https://ck.devanshag.com" >
              <img className="image_dimensions" src={ck} alt="" />
            </a>
          </a>
        </div>

        <div className="project">
          <div className="project_description">
            <div>
              <div className="project_title">Spansberry</div>
            </div>
            <div className="project_desc_card">
              <div>
                Spansberry project aims to provide a discussion platform to all
                community members which can easily organize various topics for
                discussions under different categories and start/continue
                discussion on any topic. Worked on the Figma Designs as well as
                Development of the same.
              </div>
            </div>

            <div className="tags">
              <Tag color="blue">ReactJS</Tag>
              <Tag color="blue">NodeJS</Tag>
              <Tag color="blue">ExpressJS</Tag>
              <Tag color="blue">MongoDB</Tag>
              <Tag color="blue">GraphQL</Tag>
              <Tag color="blue">Figma</Tag>
            </div>
          </div>

          <a className="project_image">
            <img className="image_dimensions" src={spansberry} alt="" />
          </a>
        </div>
        <div className="project_change">
          <div className="project_description">
            <div>
              <div className="project_title">Official Apogee'20 App</div>
            </div>
            <div className="project_desc_card">
              <div>
                Official Application Designed for Apogee'20, BITS Pilani's
                Annual Technical Fest. Users can track Events, Order Food
                online, keep track of Kind Store points and Event Signings!
              </div>
            </div>

            <div
              className="tags"
              style={{
                justifyContent: "flex-end",
                paddingRight: 0,
                paddingLeft: "50px",
              }}
            >
              <Tag color="blue">Adobe XD</Tag>
              <Tag color="blue">Adobe Photoshop</Tag>
              <Tag color="blue">Adobe Illustrator</Tag>
            </div>
          </div>

          <a
            className="project_image"
            style={{
              boxShadow: "none",
              display: "flex",
              gap: "0 10px",
              gridColumn: "1/10",
            }}
          >
            <div>
              <img className="image_dimensions" src={apogee1} alt="" />
            </div>
            <div>
              <img className="image_dimensions" src={apogee2} alt="" />
            </div>
            <div>
              <img className="image_dimensions" src={apogee3} alt="" />
            </div>
          </a>
        </div>
        <div className="project">
          <div className="project_description">
            <div>
              <div className="project_title">Anonymous Discussion</div>
            </div>
            <div className="project_desc_card">
              <div>
                A Chat Room application using ReactJS, NodeJS and Socket.io, in
                which user can join a room with a roomID and chat anonymously
                with preferred username.
              </div>
            </div>

            <div className="tags">
              <Tag color="blue">ReactJS</Tag>
              <Tag color="blue">NodeJS</Tag>
              <Tag color="blue">ExpressJS</Tag>
              <Tag color="blue">MongoDB</Tag>
              <Tag color="blue">Socket.IO</Tag>
            </div>
          </div>

          <a className="project_image">
            <img
              className="image_dimensions"
              src={anonymousDiscussion}
              alt=""
            />
          </a>
        </div>
      </div>
    </Layout>
  );
};

export default ProjectsPage;
